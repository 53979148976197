<template>
  <v-row dense class="text-right">
    <v-col>
      <comment-btn-dialog @getSessionData="emitGetSessionData" :student-data="studentData" />
    </v-col>
    <v-col v-if="showTestBtn">
      <test-btn-dialog
        @getSessionData="emitGetSessionData"
        :student-data="studentData"
        :test-record="testRecord"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {
    commentBtnDialog: () => {
      return import(
        "@/modules/cardioguard-trainer/components/views/trainer-session/StudentActionsComment"
      );
    },
    testBtnDialog: () => {
      return import(
        "@/modules/cardioguard-trainer/components/views/trainer-session/StudentActionsTestDialog"
      );
    },
  },
  props: {
    studentData: { type: Object, required: true },
    showTestBtn: { type: Boolean, default: false },
    testRecord: { type: Object || null, required: false },
  },
  methods: {
    emitGetSessionData() {
      this.$emit("getSessionData");
    },
  },
};
</script>

<style scoped></style>
